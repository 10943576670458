import React, { useState, useEffect } from 'react';
import './App.css';
import IntroAnimation from './IntroAnimation';

function HomePage() {
  const [showIntro, setShowIntro] = useState(true);
  const [contentVisible, setContentVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowIntro(false);
      setContentVisible(true);
    }, 4000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="App">
      {showIntro && <IntroAnimation />}
      <div className={`container ${contentVisible ? 'fade-in' : ''}`}>
        <div className="profile">
          <img 
            src="logo.jpg" 
            alt="myk mour" 
            className="profile-image" 
          />
          <h1>MYK MOUR</h1>
          <p>Director & Performer</p>
        </div>

        <div className={`links ${contentVisible ? 'fade-in' : ''}`}>
          <a href="https://myk-mour.manyvids.com" className="button" target="_blank" rel="noopener noreferrer">
            ManyVids
          </a>
          <a href="https://www.xvideos.com/channels/mykmour#_tabRed" className="button" target="_blank" rel="noopener noreferrer">
            XVideos
          </a>
          <a href="https://pornbox.com/application/studio/13421" className="button" target="_blank" rel="noopener noreferrer">
            PornBox
          </a>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
