import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';  // Global CSS
import App from './App';  // The main App component

const root = ReactDOM.createRoot(document.getElementById('root'));  // Find the root element in the HTML
root.render(
  <React.StrictMode>
    <App />  {/* Render the App component, which contains routing */}
  </React.StrictMode>
);


